<template>
  <el-scrollbar>
    <div class="artV_body_all">
      <div style="width: 100%">
        <el-page-header title="返回" content="文章详情" @back="goBack" />
      </div>

      <div style="width: 90%; padding: 20px">
        <div class="artV_title">{{ data.a_title }}</div>
        <div class="artV_info">
          <div class="artV_time">
            <div style="color: #409eff">时间：</div>
            <div>{{ data.time }}</div>
          </div>

          <div class="artV_author">
            <img :src="imgSrc" style="width: 50px; height: 50px" alt="" />
            <div class="artV_author_text">
              <div class="artV_time">
                <div style="color: #409eff">ID：</div>
                <div>{{ data.user_id }}</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="artV_body">
            <div class="artV_content">
              {{ data.a_subtenc }}
            </div>
            <div style="margin-left: 20px">
              <div class="artV_demo-image">
                <div v-for="fit in fits" :key="fit" class="actV_block">
                  <el-image
                    v-for="(item, index) in data.a_image"
                    :preview-src-list="data.a_image"
                    style="width: 280px; height: 280px; margin: 30px"
                    :key="index"
                    :src="item"
                    :fit="fit"
                  ></el-image>
                </div>
              </div>
            </div>
          </div>
          <div class="artV_bottom">
            <el-button type="primary" @click.prevent="Change">通过</el-button>
            <el-button type="danger" @click.prevent="Change1">拒绝</el-button>
            <el-button type="info">删除</el-button>
          </div>
        </div>
      </div>
    </div>
  </el-scrollbar>
</template>

<script>
import { getCurrentInstance, onMounted, reactive } from "vue";
import { ElMessage } from "element-plus";
export default {
  name: "articleV",
  setup() {
    const { proxy } = getCurrentInstance();
    const fits = ["scale-down"];
    const imgSrc = require("../assets/zyhylogo.png");
    const data = reactive({
      article_id: 1,
      a_title: "",
      a_subtenc: "",
      time: "",
      user_id: 1,
      a_image: [],
    });
    onMounted(() => {
      if (proxy.$route.query.article_id != undefined) {
        data.article_id = proxy.$route.query.article_id;
      }
      console.log(proxy.$route);
      articleVInfo();
    });
    function Change() {
      proxy.$http
        .get("/ArticleController/updateArticle", {
          article_id: data.article_id,
          a_status:1
        })
        .then((res) => {
          console.log(res);
          if (res.code===1){
            ElMessage({
              type: "success",
              message: "文章已通过",
            });
          }
        });
    }
    function Change1() {
      proxy.$http
        .get("/ArticleController/updateArticle", {
          article_id: data.article_id,
          a_status:0
        })
        .then((res) => {
          console.log(res);
          if (res.code===1){
            ElMessage({
              type: "success",
              message: "文章已拒绝",
            });
          }
        });
    }
    function articleVInfo() {
      proxy.$http
        .get("/ArticleController/queryArticle", {
          article_id: data.article_id,
        })
        .then((res) => {
          console.log(res);
          data.a_title = res[0].a_title;
          data.a_subtenc = res[0].a_subtenc;
          data.time = res[0].time;
          data.user_id = res[0].user_id;
          let image = res[0].a_image;
          let url = image.split(",");
          let i = 0;
          console.log(url);
          for (i; i < url.length; i++) {
            let src = url[i].split("'");
            console.log(src[1]);
            data.a_image = data.a_image.concat(src[1]);
          }
          console.log(data.a_image);
        });
    }
    function goBack() {
      proxy.$router.go(-1);
    }
    return {
      imgSrc,
      fits,
      data,
      Change,
      Change1,
      goBack,
    };
  },
};
</script>

<style>
.artV_body_all {
  background-color: white;
  padding: 20px 20px 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.artV_title {
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 2px;
}
.artV_time {
  display: flex;
}
.artV_author {
  display: flex;
  align-items: center;
}
.artV_author_text {
  margin-left: 10px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.artV_info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid #f2f2f2;
}
.artV_body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.artV_content {
  letter-spacing: 1px;
  font-size: 16px;
  line-height: 26px;
  margin-top: 20px;
  width: 95%;
}
.artV_demo-image {
  display: flex;
  flex-flow: wrap;
}
.actV_block {
  margin: 0 10px -20px 10px;
}
.artV_bottom {
  display: flex;
  justify-content: end;
}
</style>
